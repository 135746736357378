import React from "react"
import MainTemplate from "templates/Main/MainTemplate";
import {navigate} from "gatsby-link";

class Index extends React.Component {
    render() {
        if (this.props.user) {
            navigate(`/app/dashboard/`)
        }

        return (
            <MainTemplate>
            </MainTemplate>
        )
    }
}

export default Index
